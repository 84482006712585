var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "work-provedores" }, [
      _c("div", { staticClass: "mb-4" }, [
        _c("span", { staticClass: "ml-2 " }, [_vm._v("Provedor")]),
        _vm._v(" "),
        _c("input", {
          staticClass:
            "border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent h-10 rounded-full pl-4  w-full mt-2",
          attrs: {
            placeholder: "Elige el provedor",
            required: "",
            name: "provedor",
            id: "search_provedores",
            list: "provedores"
          },
          on: { input: _vm.getProvedores }
        }),
        _vm._v(" "),
        _c(
          "datalist",
          { attrs: { id: "provedores" } },
          _vm._l(_vm.provedores, function(provedor) {
            return _c("option", [_vm._v(_vm._s(provedor.nombre))])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }