var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "z-11" },
      [
        _c("apexchart", {
          ref: "realtimeChart",
          attrs: {
            type: "bar",
            height: "400",
            options: _vm.chartOptions,
            series: _vm.series
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-8" },
      _vm._l(_vm.prov, function(provedor, nombre) {
        return nombre !== "month" && nombre !== "year"
          ? _c(
              "div",
              { staticClass: "border rounded-lg -px-2 md:p-2 md:w-1/2 mt-2" },
              [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "text-xl font-semibold" }, [
                      _vm._v(_vm._s(nombre))
                    ]),
                    _vm._v(" "),
                    _vm._l(provedor, function(factura) {
                      return factura.fecha
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex mt-2 border p-2 rounded-lg bg-red-100 justify-between"
                            },
                            [
                              _c("div", { staticClass: "ml-3" }, [
                                _vm._v(_vm._s(factura.fecha))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml-3" }, [
                                _vm._v(_vm._s(factura.type))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml-3" }, [
                                _vm._v(_vm._s(factura.numero))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml-3" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(factura.total) + "€")
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    })
                  ],
                  2
                )
              ]
            )
          : _vm._e()
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }