<template>
  <div>
    <div class="md:flex">
        <date-picker v-model="time" range placeholder='Rango de fecha' @change="handleChange" format="DD-MMM-YYYY" :lang='lang' :disabled-date="notAfterToday"></date-picker>
        <div class="md:ml-6 xs:pt-8">
                <div class="relative text-gray-900 focus-within:text-gray-900 w-full mt-4 md:mt-0">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button type="submit" class="p-1 focus:outline-none focus:shadow-outline">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </button>
                  </span>
                  <input type="text" name="search_caja" class="py-2 text-md rounded-full pl-12 focus:outline-none w-full border -mt-1" placeholder="Buscar vales o gastos" autocomplete="off" id="search_caja" @input="searchCaja">
                </div>
        </div>
    </div>
    <div class="mt-4 w-full">
      <search-caja v-if="isActiveGastos" :complete="complete"></search-caja>
        <div class="grid xs:grid-cols-1 md:grid-cols-3 md:gap-5" v-if="isActiveCaja">
              <div class="p-2 rounded-lg bg-gray-100 w-full" v-for="caja, dias in cajas" v-if="dias.length > 0">
                  <h2 class="border-b text-center">{{ dias | formatDate }}</h2>
                  <div class="">
                      <div class="w-full h-full mt-4" v-for="dia, index in caja">
                        <p class="text-xl font-semibold mb-4 center">{{ dia.interval.toUpperCase() }}</p>
                        <div class="flex justify-between">
                          <div>
                            <p>Vendido: <strong>{{ dia.total }}&euro;</strong></p>
                            <p>Visas: <strong>{{ dia.total_visa }}&euro;</strong></p>
                          </div>
                          <div>
                            <p>Otros: <strong>{{ dia.otros }}&euro;</strong></p>
                            <p>Efectivo: <strong>{{ dia.efectivo }}&euro;</strong></p>
                          </div>
                        </div>
                        <div class="border rounded-lg mt-2 bg-red-200 -m-2 pb-2 px-2" v-if="dia.gasto.length > 0">
                          <p class="text-lg font-semibold my-2 border-b text-center">Gastos</p>
                          <div class="gastos flex justify-between h-auto" v-for="gasto in dia.gasto">
                              <div class="w-auto">
                                {{gasto.type}}
                              </div> 
                              <div class="w-auto">
                                {{gasto.nombre}}
                              </div>
                              <div class="w-auto pr-3" v-if="dia.interval === 'dia'">
                                {{gasto.importe}}&euro;
                              </div>
                              <div class="w-auto" v-else="">
                                {{gasto.importe}}&euro;
                              </div>                
                          </div>
                        </div>
                        <div class="border rounded-lg mt-2 bg-green-200 -m-2 p-2" v-if="dia.ingreso.length > 0">
                          <p class="text-lg font-semibold my-2 border-b text-center">Ingresos</p>
                          <div class="flex justify-between" v-for="ingreso, inde in dia.ingreso">
                              <div>
                                {{ingreso.banco}}
                              </div> 
                              <div>
                                {{ingreso.importe}}&euro;
                              </div>                
                          </div>
                        </div>
                        <div class="bg-yellow-200 rounded-lg p-2 -mx-2 -mb-2">
                          <p class="text-2xl text-center font-semibold">Arrastre</p>
                          <div class="">
                            <div class="w-full text-center text-xl">
                              <strong>{{ dia.arrastre }}&euro;</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
        </div>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    name: 'Range',
    components: { DatePicker},
    mounted(){
      this.getLastThreeDays();
    },
    data() {
      return {
        complete: [],
        time: null,
        isActiveGastos: false,
        isActiveCaja: true,
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          }
        },
        cajas: []
      };
    },
    methods: {
        handleChange(value, type) {
          if (value[0] != null) {
            axios.post('/caja/show_by_range', {
                bday: value[0].getDate(),
                bmonth: value[0].getMonth()+1,
                byear: value[0].getFullYear(),
                eday: value[1].getDate(),
                emonth: value[1].getMonth()+1,
                eyear: value[1].getFullYear(),
              }).then((response) => {
                  this.cajas = response.data;
              }, (error) => {
                  console.log(error);
            });
          }
        },
        notAfterToday(date) {
          return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        getLastThreeDays(){
          var bdate = new Date();
          bdate.setDate(bdate.getDate() - 2);

          var edate = new Date();

          axios.post('/caja/show_by_range', {
              bday: bdate.getDate(),
              bmonth: bdate.getMonth() + 1,
              byear: bdate.getFullYear(),
              eday: edate.getDate(),
              emonth: edate.getMonth() + 1,
              eyear: edate.getFullYear(),
            }).then((response) => {
                this.cajas = response.data;
            }, (error) => {
                console.log(error);
          });

        },
        searchCaja() {
          if (document.getElementById('search_caja').value.length > 0) {
            axios.post('/caja/show_gastos', {
              searchcaja: document.getElementById('search_caja').value,
              }).then((response) => {
                  this.complete = response.data;
                  this.isActiveGastos = true;
                  this.isActiveCaja = false;
              }, (error) => {
                  console.log(error);
            });
          }else{
            this.isActiveGastos = false;
            this.isActiveCaja = true;
          }
          
        }
      }
  };
</script>