
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'



Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.component('apexchart', VueApexCharts);
Vue.component('date-range', require('./components/DateRange.vue').default);
Vue.component('date-search', require('./components/DateSearch.vue').default);
Vue.component('add-gastos', require('./components/AddGastos.vue').default);
Vue.component('add-ingresos', require('./components/AddIngresos.vue').default);
Vue.component('search-caja', require('./components/SearchCaja.vue').default);
Vue.component('search-provedor', require('./components/SearchProvedor.vue').default);
Vue.component('current-month-chart', require('./components/ProvedoresCurrentMonthChart.vue').default);
Vue.component('provedores-date-range', require('./components/ProvedoresByMonth.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});
