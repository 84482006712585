<template>
  <div>
   <!--  <form> -->
      <div class="work-gastoss">
        <div class="form-row" v-for="(gastos, index) in gasto" :key="index">
          <div class="md:flex mt-4">
              <select class="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent h-10 rounded-full pl-4 mb-4 mr-4 w-full form-select block" :name="`gasto[${index}][type]`" id="gasto-type">
                  <option disabled="" selected="">Tipo</option>
                  <option value="ticket">Ticket</option>
                  <option value="albaran">Albaran</option>
                  <option value="factura">Factura</option>
                  <option value="adelanto">Adelanto</option>
              </select>
              <input v-model="gastos.nombre" :name="`gasto[${index}][nombre]`" class="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent h-10 rounded-full pl-4 mb-4 mr-4 w-full" placeholder="Nombre" type="text" id="gasto-nombre">
              <input v-model="gastos.importe" :name="`gasto[${index}][importe]`" class="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent h-10 rounded-full pl-4 mb-4 w-full" placeholder="Importe" type="tel" id="gasto-importe" pattern="[0-9.]+">
              <button type="button" @click="removeGasto(index)" class="outline-none" v-if="index > 0">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 text-red-500 -mt-4">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
              </button>
          </div>
        </div>
      </div>

      <button class="flex pt-2 px-2 w-auto" @click="addGasto" type="button">
        <svg xmlns="http://www.w3.org/flex2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-12 text-green-600">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span class="ml-2 text-lg"></span>
      </button>
    <!-- </form> -->
  </div>
</template>

<script>

export default {
  name: "App",
  
  data: () => ({
    gasto: [{
        type: '',
        nombre: '',
        importe: ''
    }
    ]
  }),

  methods: {
    addGasto () {
      this.gasto.push({
        type: '',
        nombre: '',
        importe: ''
      })
    },

    removeGasto (index) {
      this.gasto.splice(index, 1)
    },

    // submit () {
    //   const data = {
    //     gasto: this.gasto
    //   }
    //   alert(JSON.stringify(data, null, 2))
    // }
  }
};
</script>