<template>
  <div>
   <!--  <form> -->
      <div class="work-provedores">
          <div class="mb-4">
            <span class="ml-2 ">Provedor</span>
            <input class="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent h-10 rounded-full pl-4  w-full mt-2" placeholder="Elige el provedor" required="" name="provedor" id="search_provedores" @input="getProvedores" list="provedores">
            <datalist id="provedores">
                <option v-for="provedor in provedores">{{ provedor.nombre }}</option>
            </datalist>
          </div>
      </div>

    <!-- </form> -->
  </div>
</template>

<script>

export default {
  name: "App",
  
  data: () => ({
    provedores: {}
  }),

  methods: {
    addProvedor () {
      this.provedor.push({
        nombre: '',
      })
    },
    getProvedores () {
      if (document.getElementById('search_provedores').value.length > 0) {
          let q = document.getElementById('search_provedores').value;
            axios.get('/factura/provedores/res?q='+q, {
              }).then((response) => {
                  this.provedores = response.data;
                  // console.log(response.data);
              }, (error) => {
                  console.log(error);
            });
          }
    }
  }
};
</script>