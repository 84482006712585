<template>
	<div>
		<div class="grid grid-cols-1 md:grid-cols-3 md:gap-3">
			<div v-for="gastos,month in complete" class="bg-gray-100 rounded-xl relative mb-4 md:mb-0">
			<h2 class="text-center text-xl font-semibold mt-2 pb-2 border-b">{{ month }}</h2>
				<div class="mb-8">
					<div v-for="gasto in gastos" class="flex justify-between px-2" >
						<span>{{ gasto.nombre }}</span>
						<span class="font-semibold" v-if="gasto.importe">{{ gasto.importe }}&euro;</span>
						<span>{{ gasto.fecha | formatDate}}</span>
					</div>
				</div>
				<div class="total text-center absolute bottom-0 inset-x-0 text-red-700 pb-1" v-if="gastos.total_month">
					<p class="text-xl font-semibold">Total mes: <strong>{{ gastos.total_month }}&euro;</strong></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props: { complete: {}},
		mounted(){
		},
		data() {
			return {
				
			}
		}
	}
</script>