var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "work-gastoss" },
      _vm._l(_vm.gasto, function(gastos, index) {
        return _c("div", { key: index, staticClass: "form-row" }, [
          _c("div", { staticClass: "md:flex mt-4" }, [
            _c(
              "select",
              {
                staticClass:
                  "border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent h-10 rounded-full pl-4 mb-4 mr-4 w-full form-select block",
                attrs: { name: "gasto[" + index + "][type]", id: "gasto-type" }
              },
              [
                _c("option", { attrs: { disabled: "", selected: "" } }, [
                  _vm._v("Tipo")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "ticket" } }, [
                  _vm._v("Ticket")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "albaran" } }, [
                  _vm._v("Albaran")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "factura" } }, [
                  _vm._v("Factura")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "adelanto" } }, [
                  _vm._v("Adelanto")
                ])
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: gastos.nombre,
                  expression: "gastos.nombre"
                }
              ],
              staticClass:
                "border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent h-10 rounded-full pl-4 mb-4 mr-4 w-full",
              attrs: {
                name: "gasto[" + index + "][nombre]",
                placeholder: "Nombre",
                type: "text",
                id: "gasto-nombre"
              },
              domProps: { value: gastos.nombre },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(gastos, "nombre", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: gastos.importe,
                  expression: "gastos.importe"
                }
              ],
              staticClass:
                "border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent h-10 rounded-full pl-4 mb-4 w-full",
              attrs: {
                name: "gasto[" + index + "][importe]",
                placeholder: "Importe",
                type: "tel",
                id: "gasto-importe",
                pattern: "[0-9.]+"
              },
              domProps: { value: gastos.importe },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(gastos, "importe", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            index > 0
              ? _c(
                  "button",
                  {
                    staticClass: "outline-none",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.removeGasto(index)
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "h-6 text-red-500 -mt-4",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d:
                              "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "flex pt-2 px-2 w-auto",
        attrs: { type: "button" },
        on: { click: _vm.addGasto }
      },
      [
        _c(
          "svg",
          {
            staticClass: "h-12 text-green-600",
            attrs: {
              xmlns: "http://www.w3.org/flex2000/svg",
              fill: "none",
              viewBox: "0 0 24 24",
              stroke: "currentColor"
            }
          },
          [
            _c("path", {
              attrs: {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
                d: "M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "ml-2 text-lg" })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }