var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "md:flex" },
      [
        _c("date-picker", {
          attrs: {
            range: "",
            placeholder: "Rango de fecha",
            format: "DD-MMM-YYYY",
            lang: _vm.lang,
            "disabled-date": _vm.notAfterToday
          },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.time,
            callback: function($$v) {
              _vm.time = $$v
            },
            expression: "time"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "md:ml-6 xs:pt-8" }, [
          _c(
            "div",
            {
              staticClass:
                "relative text-gray-900 focus-within:text-gray-900 w-full mt-4 md:mt-0"
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "absolute inset-y-0 left-0 flex items-center pl-2"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "p-1 focus:outline-none focus:shadow-outline",
                      attrs: { type: "submit" }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "w-6 h-6",
                          attrs: {
                            fill: "none",
                            stroke: "currentColor",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            viewBox: "0 0 24 24"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass:
                  "py-2 text-md rounded-full pl-12 focus:outline-none w-full border -mt-1",
                attrs: {
                  type: "text",
                  name: "search_caja",
                  placeholder: "Buscar vales o gastos",
                  autocomplete: "off",
                  id: "search_caja"
                },
                on: { input: _vm.searchCaja }
              })
            ]
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-4 w-full" },
      [
        _vm.isActiveGastos
          ? _c("search-caja", { attrs: { complete: _vm.complete } })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveCaja
          ? _c(
              "div",
              { staticClass: "grid xs:grid-cols-1 md:grid-cols-3 md:gap-5" },
              _vm._l(_vm.cajas, function(caja, dias) {
                return dias.length > 0
                  ? _c(
                      "div",
                      { staticClass: "p-2 rounded-lg bg-gray-100 w-full" },
                      [
                        _c("h2", { staticClass: "border-b text-center" }, [
                          _vm._v(_vm._s(_vm._f("formatDate")(dias)))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {},
                          _vm._l(caja, function(dia, index) {
                            return _c(
                              "div",
                              { staticClass: "w-full h-full mt-4" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-xl font-semibold mb-4 center"
                                  },
                                  [_vm._v(_vm._s(dia.interval.toUpperCase()))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c("div", [
                                      _c("p", [
                                        _vm._v("Vendido: "),
                                        _c("strong", [
                                          _vm._v(_vm._s(dia.total) + "€")
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v("Visas: "),
                                        _c("strong", [
                                          _vm._v(_vm._s(dia.total_visa) + "€")
                                        ])
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("p", [
                                        _vm._v("Otros: "),
                                        _c("strong", [
                                          _vm._v(_vm._s(dia.otros) + "€")
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v("Efectivo: "),
                                        _c("strong", [
                                          _vm._v(_vm._s(dia.efectivo) + "€")
                                        ])
                                      ])
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                dia.gasto.length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "border rounded-lg mt-2 bg-red-200 -m-2 pb-2 px-2"
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-lg font-semibold my-2 border-b text-center"
                                          },
                                          [_vm._v("Gastos")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(dia.gasto, function(gasto) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass:
                                                "gastos flex justify-between h-auto"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w-auto" },
                                                [
                                                  _vm._v(
                                                    "\n                              " +
                                                      _vm._s(gasto.type) +
                                                      "\n                            "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "w-auto" },
                                                [
                                                  _vm._v(
                                                    "\n                              " +
                                                      _vm._s(gasto.nombre) +
                                                      "\n                            "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              dia.interval === "dia"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "w-auto pr-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                              " +
                                                          _vm._s(
                                                            gasto.importe
                                                          ) +
                                                          "€\n                            "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "w-auto" },
                                                    [
                                                      _vm._v(
                                                        "\n                              " +
                                                          _vm._s(
                                                            gasto.importe
                                                          ) +
                                                          "€\n                            "
                                                      )
                                                    ]
                                                  )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                dia.ingreso.length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "border rounded-lg mt-2 bg-green-200 -m-2 p-2"
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-lg font-semibold my-2 border-b text-center"
                                          },
                                          [_vm._v("Ingresos")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(dia.ingreso, function(
                                          ingreso,
                                          inde
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between"
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  "\n                              " +
                                                    _vm._s(ingreso.banco) +
                                                    "\n                            "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm._v(
                                                  "\n                              " +
                                                    _vm._s(ingreso.importe) +
                                                    "€\n                            "
                                                )
                                              ])
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "bg-yellow-200 rounded-lg p-2 -mx-2 -mb-2"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-2xl text-center font-semibold"
                                      },
                                      [_vm._v("Arrastre")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {}, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full text-center text-xl"
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(dia.arrastre) + "€")
                                          ])
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  : _vm._e()
              }),
              0
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }