<template>
	<div>
		<div class="z-11">
		  <apexchart type="bar" height="400" :options="chartOptions" :series="series" ref="realtimeChart"></apexchart>
		</div>
		<div class="mt-8">
			<div v-for="provedor, nombre in prov" class="border rounded-lg -px-2 md:p-2 md:w-1/2 mt-2" v-if="nombre !== 'month' && nombre !== 'year'">
				<div>
					<div class="text-xl font-semibold">{{ nombre }}</div>
					<div v-for="factura in provedor" class="flex mt-2 border p-2 rounded-lg bg-red-100 justify-between" v-if="factura.fecha">
						<div class="ml-3">{{ factura.fecha }}</div>
						<div class="ml-3">{{ factura.type }}</div>
						<div class="ml-3">{{ factura.numero }}</div>
						<div class="ml-3"><strong>{{ factura.total }}&euro;</strong></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		props: {
			byMonth: {
				type: Array
			},
		},
		created() {
			this.getCurrentMonth()

		},
		mounted () {
		},
		watch: { 
	      	byMonth: function() {
				this.updateMonth();
			}
	      },
		data () {
			return {
				prov: {},
			  chartOptions: {
			  	title: {
	              text: '',
	              align: 'center',
	              style: {
	              	fontSize: '24px',
	              	fontWeight: '600'
	              }
	            },
			    chart: {
			      type: 'bar',
			      height: 0,
			    },
			    colors: ['#6366f1'],
			    plotOptions: {
			      bar: {
			        borderRadius: 4,
			        horizontal: true,
			        barHeight: '50%',
			      }
			    },
			    dataLabels: {
				formatter: function (value) {
					return value + "€";
				},
			      enabled: true,
			      offsetX: 0,
			      textAnchor: 'middle',
			      style: {
			        fontSize: '18px',
			        colors: ['#000000']
			      },
			      dropShadow: {
				      enabled: false,
				      top: 1,
				      left: 1,
				      blur: 1,
				      color: '#000',
				      opacity: 0.45
				  },
			    },
			    tooltip: {
			    	onDatasetHover: {
			          highlightDataSeries: true,
			      },
			  	},
			    xaxis: {
			      categories: [],
			      labels: {
			      	formatter: function (value) {
      					return value + "€";
      				},
		            style: {
		                fontSize: '18px'
		            }
		       	  },
			    },
			    yaxis: {
			    	labels: {
			    		style: {
			    			fontSize: '18px',
			    		}
			    	}
			    }
			  },
			  series: [{
			    data: []
			  }],
			}
		},
		methods: {
			getCurrentMonth () {

				axios.get('/provedores/get_provedores', {
			      }).then((response) => {
			      	var provedores = this.chartOptions.xaxis.categories;
			      	var total = this.series[0].data;
			      	this.prov = response.data;

			          $.each(response.data, function(key, value) {
					     if (key !== 'month' && key !== 'year') {
					     	provedores.push(key)
					     	total.push(value.total_month.toFixed(2))
					     }
					   });
					   this.updateTitle();				
			      }, (error) => {
			          console.log(error);
			    });
			},
			updateTitle() {
				const monthName = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
				this.$refs.realtimeChart.updateOptions({
				 	title: {
				 		text: monthName[this.prov.month]+' ' +this.prov.year,
				 	}
			      }, false, false, true);
			},
			updateMonth() {
				this.chartOptions.xaxis.categories = [];
				this.series[0].data = [];
				var provedores = this.chartOptions.xaxis.categories;
			    var total = this.series[0].data;
				this.prov = this.byMonth;
				const monthName = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

				$.each(this.byMonth, function(key, value) {
					if (key !== 'month' && key !== 'year') {
					     provedores.push(key)
					     total.push(value.total_month.toFixed(2))
				 	}
			   });

			 this.$refs.realtimeChart.updateSeries([{
		        data: this.series[0].data,
		      }], false, true);

			 this.$refs.realtimeChart.updateOptions({
			 	title: {
			 		text: monthName[this.prov.month]+' ' +this.prov.year,
			 	},
		        xaxis: {
			      categories: this.chartOptions.xaxis.categories
			    }
		      }, false, false, true);

			}
		}
	}
</script>