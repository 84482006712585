<template>
  <div>
    <date-picker v-model="time" type="month" placeholder='Rango de fecha facturas' @change="handleChange" :lang='lang' format="MM/YYYY" :disabled-date="notAfterToday"></date-picker>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    name: 'Month',
    components: { DatePicker },
    data() {
      return {
        time: null,
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          }
        }
      };
    },
    methods: {
        handleChange(value, type) {
          let csrf = document.querySelector('meta[name="csrf-token"]').content;
          let month = value.getMonth()+1;
          let year = value.getFullYear();

          if (month < 10) {
            window.location = '/factura/search_by_date?_token='+csrf+'&luna=0'+month+'&an='+year+'&submit=';
          }else{
            window.location = '/factura/search_by_date?_token='+csrf+'&luna='+month+'&an='+year+'&submit=';
          }
          
        },
        notAfterToday(date) {
          return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
      }
  };
</script>