var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("date-picker", {
        attrs: {
          type: "month",
          placeholder: "Rango de fecha facturas",
          lang: _vm.lang,
          format: "MM/YYYY",
          "disabled-date": _vm.notAfterToday
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.time,
          callback: function($$v) {
            _vm.time = $$v
          },
          expression: "time"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }