var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "grid grid-cols-1 md:grid-cols-3 md:gap-3" },
      _vm._l(_vm.complete, function(gastos, month) {
        return _c(
          "div",
          { staticClass: "bg-gray-100 rounded-xl relative mb-4 md:mb-0" },
          [
            _c(
              "h2",
              {
                staticClass:
                  "text-center text-xl font-semibold mt-2 pb-2 border-b"
              },
              [_vm._v(_vm._s(month))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-8" },
              _vm._l(gastos, function(gasto) {
                return _c("div", { staticClass: "flex justify-between px-2" }, [
                  _c("span", [_vm._v(_vm._s(gasto.nombre))]),
                  _vm._v(" "),
                  gasto.importe
                    ? _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(_vm._s(gasto.importe) + "€")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("formatDate")(gasto.fecha)))
                  ])
                ])
              }),
              0
            ),
            _vm._v(" "),
            gastos.total_month
              ? _c(
                  "div",
                  {
                    staticClass:
                      "total text-center absolute bottom-0 inset-x-0 text-red-700 pb-1"
                  },
                  [
                    _c("p", { staticClass: "text-xl font-semibold" }, [
                      _vm._v("Total mes: "),
                      _c("strong", [_vm._v(_vm._s(gastos.total_month) + "€")])
                    ])
                  ]
                )
              : _vm._e()
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }