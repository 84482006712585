<template>
  <div>
    <date-picker v-model="time" type="month" placeholder='Provedores por mes' @change="handleChange" :lang='lang' format="MM/YYYY" :disabled-date="notAfterToday"></date-picker>

    <div class="mt-8">
      <current-month-chart :byMonth="prov"></current-month-chart>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    name: 'ProvedoresByMonth',
    components: { DatePicker },
    data() {
      return {
        time: null,
        prov: [],
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          }
        }
      };
    },
    methods: {
        handleChange(value, type) {
            if (value) {
              var month = value.getMonth() + 1;
              var year = value.getFullYear();

              axios.get('/provedores/get_provedores?month=0'+month+'&year='+year, {
                }).then((response) => {
                  this.prov = response.data 
                }, (error) => {
                    console.log(error);
              });
            }
          
          
        },
        notAfterToday(date) {
          return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
      }
  };
</script>